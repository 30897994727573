import React from "react"
import classnames from "classnames"

const Strong = ({ className, children }) => (
  <strong
    className={classnames(
      "text-xl pt-2 block font-normal font-body",
      className
    )}
  >
    {children}
  </strong>
)

export default Strong
