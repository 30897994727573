import React from "react"

const ButtonLink = ({ children, ...otherProps }) => (
  <button
    className="pt-2 text-xl font-light text-green-700 cursor-pointer font-body"
    {...otherProps}
  >
    {children}
  </button>
)

export default ButtonLink
