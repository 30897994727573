import React from "react"
import classnames from "classnames"

const H1 = ({ children, className, ...otherProps }) => (
  <h1
    className={classnames("font-display text-6xl pt-16", className)}
    {...otherProps}
  >
    {children}
  </h1>
)

export default H1
