import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

import { A, P, H2, H3, Link } from "../components/typography"
import ContentContainer from "../components/content"

import ContactForm from "../components/contact-form"

import DustinReco from "../components/content/reco/dustin"
import BethReco from "../components/content/reco/beth"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <ContentContainer>
      <H2 id="what">What do we do?</H2>
      <H3>We build solutions</H3>
      <P>
        When we build something, we want to create value before anything else.
        We make sure to pick the technologies that are fit for the job. We
        strive to build things fast in order to tests them with your users.
      </P>
      <H3>We provide meaning</H3>
      <P>
        We work with you and we explain the choices we make. We also provide
        everything you need to reuse and adapt what we deliver.
      </P>
      <H2 id="who">Who are we?</H2>
      <H3>Alex</H3>
      <P>
        Alex is a product oriented software engineer. He has spent most of his
        career building solutions using web technologies. While staying a "jack
        of all trades" at heart, Alex is specialized in front-end technologies.
      </P>
      <ul>
        <li>
          <Link className="text-xl" to="/cv/alex">
            View complete résumé
          </Link>
        </li>
        <li>
          <A
            className="text-xl"
            href="https://www.linkedin.com/in/astanislawski/"
            external
          >
            View profile on LinkedIn
          </A>
        </li>
      </ul>
      <H3>Dialekti</H3>
      <P>
        Dialekti is a data scientist and a manager. During her PhD, she
        cofounded her first startup. Her thesis is about information extraction
        from scientific articles. Afterward she moved quickly to executive
        positions in the private sector. She strives to be the "and" in R&amp;D.
      </P>
      <ul>
        <li>
          <A
            className="text-xl"
            href="https://www.linkedin.com/in/dvalsamou/"
            external
          >
            View profile on LinkedIn
          </A>
        </li>
      </ul>
      <H2 id="reco">What do people say about us?</H2>
      <H3>Elizabeth LeBeau – Director of engineering at Sqreen</H3>
      <BethReco />
      <H3>Dustin Whitney – CTO at Pinata</H3>
      <DustinReco />
      <H2 id="contact">Contact us</H2>
      <ContactForm />
    </ContentContainer>
  </Layout>
)

export default IndexPage
