import React, { useState } from "react"

import P from "../../typography/p"
import ButtonLink from "../../typography/ButtonLink"

const DustinReco = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      <P>
        I'm CTO of a company that has worked with Zengularity for three years.
        Alexandre was one of the first engineers to work with us, and has worked
        with us in a leadership role the entire time. With confidence I can say
        that you'd be hard pressed to find an engineer with as much breadth and
        depth of knowledge! We're building a business intelligence platform and
        the application is a mix of standard web, mobile web, big data and data
        visualization. There is not one aspect of our application that Alexandre
        didn't touch.
      </P>
      {isExpanded ? (
        <>
          <P>
            On the mobile side, he architected an HTML5/JavaScript solution that
            allowed us to develop both the standard web and mobile web
            experience at the same time with a lot of code reuse. This is
            difficult to pull off and it's something that Facebook waived the
            white flag on long ago. The solution Alexandre architected resulted
            in an app that felt every bit as performant as a native application,
            but allowed us to save time by not having to write two apps at once.
          </P>
          <P>
            On the backend/big data side of things, Alexandre put his Scala hat
            on without skipping a beat, and built a system to ingest terrabytes
            of data into both DynamoDB and Datomic. We do a lot of number
            crunching on that data and it's crucial that it be both realtime and
            performant. His insight was that most of the processing time was
            network IO, so he proposed and implemented a solution to do the
            number crunching with Scala futures. It dramatically decreased the
            amount of idle resources on our system, which translated to a much
            smaller AWS bill, and a nicely scalable solution for times when we
            need scale.
          </P>
          <P>
            Data visualizations are one of the most important aspects of our
            applications, and at one point had unfortunately fallen behind. As
            Alexandre had performed so well in his role on other teams, we asked
            him to move over to our data visualazation team and resolve the
            issues and get it back on target. Within a few months we were back
            on target. Through previous experience he had worked with R and
            ggplot and understood the benefits of that system. The changes he
            proposed, and which we ultimately adopted, fall right inline with
            the writings of Leland Wilkinson's Grammar of Graphics. After
            adpoting this framework, our data visualization capabilities are now
            one of our strongest.
          </P>
          <P>
            In addition to being a great engineer, Alexandre is a great person.
            He has the knowledge and experience to take on a leadership role,
            and it's an enjoyable experience to work with him. He's very
            thoughtful about the work he does, and communicates his ideas well.
            It's truly a privilige to work with him.
          </P>
          <ButtonLink onClick={() => setIsExpanded(false)}>
            Show less...
          </ButtonLink>
        </>
      ) : (
        <>
          <ButtonLink onClick={() => setIsExpanded(true)}>
            Show more...
          </ButtonLink>
        </>
      )}
    </>
  )
}

export default DustinReco
