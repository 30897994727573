import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
  const {
    desktopImage: {
      childImageSharp: { fluid: desktopFluid },
    },
    mobileImage: {
      childImageSharp: { fluid: mobileFluid },
    },
  } = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "header-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "header-large.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="relative h-full max-h-screen ">
      <Img
        className="absolute top-0 left-0 max-h-screen md:hidden"
        fluid={mobileFluid}
      />
      <Img
        className="absolute top-0 left-0 hidden max-h-screen md:block"
        fluid={desktopFluid}
      />
      <div className="absolute top-0 w-full pt-24 pl-4 text-center md:text-right md:top-auto md:left-auto md:bottom-0 md:right-0 md:pr-20 md:pb-10">
        <h1 className="leading-none font-display text-8xl sm:text-12xl">
          Noima
        </h1>
        <div className="text-xl font-light leading-none font-body sm:text-3xl">
          Software and data
        </div>
      </div>
    </div>
  )
}

export default Hero
