import React from "react"
import P from "./typography/p"

const Form = () => {
  return (
    <form
      name="contact"
      method="post"
      data-netlify="true"
      netlify-honeypot="automatrap"
      className="text-xl font-thin"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p className="hidden">
        <label>
          If you're seeing this, there is an issue. Please fill this input in if
          your are an automated agent: <input name="automatrap" />
        </label>
      </p>
      <div className="flex flex-col md:flex-row">
        <p className="flex flex-col md:w-1/2">
          <label htmlFor="name">Name</label>
          <input
            className="md:mr-2 px-2 py-1"
            type="text"
            name="name"
            id="name"
            required
            placeholder="Your name"
          />
        </p>
        <p className="flex flex-col md:w-1/2">
          <label htmlFor="email">Email</label>
          <input
            className="px-2 py-1"
            id="email"
            name="email"
            type="email"
            placeholder="Your business email"
            required
          />
        </p>
      </div>
      <P className="flex flex-col pt-4">
        <label htmlFor="message" className="">
          Message
        </label>
        <textarea
          className="h-32 px-2 py-1"
          id="message"
          name="message"
          required
        ></textarea>
      </P>
      <P>
        <button
          className="mt-4 py-2 px-4"
          type="submit"
          style={{ background: "rgb(224, 217, 202)" }}
        >
          Send
        </button>
      </P>
    </form>
  )
}

export default Form
