export { default as A } from "./a"
export { default as ButtonLink } from "./ButtonLink"

export { default as H1 } from "./h1"
export { default as H2 } from "./h2"
export { default as H3 } from "./h3"

export { default as Link } from "./link"

export { default as P } from "./p"

export { default as Strong } from "./strong"

export { default as PageBreak } from "./page-break"
