import React, { useState } from "react"

import P from "../../typography/p"
import ButtonLink from "../../typography/ButtonLink"

const BethReco = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      <P>
        Alexandre has a strong understanding of Front End technologies,
        techniques, and best practices. Beyond these skills, I found that he has
        a very good sense of initiative as well as ownership. He works to make
        the environment that he is a part of better - both from a delivery point
        of view but also contributing to the team itself. He is among the most
        senior and hard-working front end engineers that I have had as a part of
        my teams.
      </P>
      {isExpanded ? (
        <>
          <P>
            Overall working with Alexander is great - he knows what he's doing,
            and does a great job both putting that knowledge into action as well
            as explaining it to others. That's not a common combination of
            skills, and I was happy to have him as a part of my team at Sqreen.
          </P>
          <ButtonLink onClick={() => setIsExpanded(false)}>
            Show less...
          </ButtonLink>
        </>
      ) : (
        <>
          <ButtonLink onClick={() => setIsExpanded(true)}>
            Show more...
          </ButtonLink>
        </>
      )}
    </>
  )
}

export default BethReco
